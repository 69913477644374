<template>
  <WsMain v-if="isMounted">
    <WsInfo
      :label="$t('國家/地區代碼')"
      :value="_countryCodeValue"
    ></WsInfo>
    <WsState
      :label="$t('標題')"
      type="text"
      v-model="state.title"
    ></WsState>
    <WsState
      :label="$t('內文')"
      type="editor"
      v-model="state.content"
    ></WsState>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
// import S_API_Terms from "@/__vue2stone_cms/service/api/v1/terms";
import S_API_ContactRequestAutoReply from "@/__vue2stone_cms/service/api/v1/contact_request_auto_reply";
export default {
  data() {
    return {
      isMounted: false,
      state: {
        title: "",
        content: "",
      },
      loading: {
        submit: false,
      },
    };
  },
  methods: {
    async $_onSubmit() {
      this.loading.submit = true;
      const postData = {
        content: this.state,
        ...this._params,
      };
      postData.content = btoa(
        unescape(encodeURIComponent(JSON.stringify(postData.content)))
      );
      await S_API_ContactRequestAutoReply.update(postData);
      this.loading.submit = false;
    },
    async $_valueSet() {
      const res = await S_API_ContactRequestAutoReply.show(this._params);
      this.state.title =
        res.content && res.content.title ? res.content.title : "";
      this.state.content =
        res.content && res.content.content ? res.content.content : "";
      this.isMounted = true;
    },
  },

  computed: {
    _countryCodeValue() {
      if (this.$store.state.auth.currentUser.country_code) {
        return this.$store.state.auth.currentUser.country_code;
      } else {
        return "-";
      }
    },
    _params() {
      if (this.$store.state.auth.currentUser.country_code) {
        return {
          country_code: this.$store.state.auth.currentUser.country_code,
        };
      } else {
        return {};
      }
    },
  },

  mounted() {
    this.$_valueSet();
  },
};
</script>

<style>
</style>
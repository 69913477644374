import axios from 'axios'
export default {
  async show(params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/contact_request_auto_reply', {
          params: params
        })
        .then(res => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async update(data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/contact_request_auto_reply`, data)
        .then(res => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}